import { API_Endpoints } from "../constants/endpoints";
import { getAPI, postAPI } from "./http.service";
import { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { ErrorContext } from "../components/utils/errorContext";
import dayjs, { Dayjs } from "dayjs";
import { LoaderContext } from "../components/utils/loaderContext";
import { NotificationContext } from "../components/utils/notificationContext";

export class ReconciliationService {
  private endpoints = API_Endpoints();
  private notificationContext = useContext(NotificationContext);
  private errorContext = useContext(ErrorContext);
  private loaderContext = useContext(LoaderContext);

  getReconciliation = async ({status, ...params}: any) => {
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    const tempParams = new URLSearchParams(params);
    status?.forEach?.((element: any) => {
      tempParams.append('status', element);
    });

    this.loaderContext.setLoader(true);
    return await getAPI(this.endpoints.getReconciliationURL, tempParams)
    .then((res) => {return [res.data, null];})
    .catch((err) => {
        this.notificationContext.setNotification({...err})
        return [null, err];
    }).finally(()=>this.loaderContext.setLoader(false));
  };

  getReconciliationStatus = async (id: string) => {
    this.loaderContext.setLoader(true);
    const err = {
      title: 'Reconciliation failed',
      detail: 'The system experienced an error and the Reconciliation could not be completed. Please try again.',
    }
    return await getAPI(this.endpoints.getBaseUrl + 'reconciliations/' + id + '/runStatus', null, err)
      .then((res) => res.data)
      .catch((err) => {
        this.notificationContext.setNotification({ ...err })
      }).finally(() => this.loaderContext.setLoader(false));
  };

    commitFile = async (id:string) => {
      // this.loaderContext.setLoader(true);
      const endpoint = this.endpoints.getFileUploadURL + id + "/commitupload";
      return await postAPI(endpoint, {})
        .then((res: AxiosResponse) => {
          return res;
        })
        .catch((err) => {
          this.notificationContext.setNotification({...err})
      }).finally(()=> {this.loaderContext.setLoader(false)})
    };
  
    allocateUpload = async (id:string) => {
      this.loaderContext.setLoader(true);
      const endpoint = this.endpoints.getFileUploadURL + id + "/allocateUpload";
      return await postAPI(endpoint, {})
        .then((res: AxiosResponse) => {
          return res;
        })
        .catch((err) => {
          this.notificationContext.setNotification({...err})
      }).finally(()=> this.loaderContext.setLoader(false));
    };
  

  createReconciliation = async (data: any) => {
    if (!dayjs(data.dateRange[0]).isValid() || !dayjs(data.dateRange[1]).isValid()) {
      throw new Error("Invalid date format in dateRange");
    }
    const periodStartDateUtc = dayjs(data.dateRange[0]).format("YYYY-MM-DD");
    const periodEndDateUtc = dayjs(data.dateRange[1]).format("YYYY-MM-DD");

    // Constructing the body dynamically
    const body = {
      name: data?.name,
      templateId: data?.template,
      ruleSetId: data?.ruleset,
      sourceDataFilter: {
        periodFilter: {
          periodStartDate: periodStartDateUtc,
          periodEndDate: periodEndDateUtc,
        },
        productCategory: data?.productCategory,
        vendorId: data?.vendor,
      },
    };

    this.loaderContext.setLoader(true);
    return await postAPI(this.endpoints.createReconciliationURL, body).then((res: AxiosResponse) => {
      return res;
    }).catch((err) => {
        this.loaderContext.setLoader(false);
        this.notificationContext.setNotification({ ...err })
      });
  };

  getVendorList = async () => {
    return await getAPI(this.endpoints.getAllVendorURL).then((res) => {
        return res.data;
      })
      .catch((err) => {
        this.notificationContext.setNotification({...err})
      });
  };

  getProductCategoryList = async () => {
    return await getAPI(this.endpoints.getProductCatrgoryURL).then((res) => {
        return res.data;
      })
      .catch((err) => {
        this.notificationContext.setNotification({...err})
      });
  };

  getNotifications = async(id: number) => {
    return await getAPI(this.endpoints.reconciliationBaseURL + id).then((res) => {
      return res.data;
    })
    .catch((err) => {
      this.notificationContext.setNotification({...err})
    });
  }

    getUserInfo = async (userId:any) => {
        this.loaderContext.setLoader(true);
        return await getAPI(this.endpoints.getUserInfoURL+userId)
            .then(res => {
                this.loaderContext.setLoader(false);
                return Promise.resolve(res.data);
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({...err})
            });
    }
}