import axios, { AxiosError, AxiosResponse } from 'axios';
import { getAuthToken } from "../auth/hooks/use-token";
import { RemoveEmptyAttributes } from '../components/utils/commonFunctions';

const getOptions = () => {
  const token = getAuthToken();

  const options = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`
      },
      body: '',
      params: {},
      timeout: 60*1000,
  }
  return options;
}

const getOptionsV2 = () => {
    const token = getAuthToken();

    const options = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: '',
        params: {},
        timeout: 60*1000*5,
    }
    return options;
}

const getErrorObject = (err: AxiosError | any) => {
  let errorObj = {};
  const additionalDescriptionFn = (val: any) =>{
    const tempArr: string[] = []
    if(val){
      var keys = Object.keys(val);
      keys.forEach(key => {
        tempArr.push(...val[key])
      })
    }
    return tempArr;
  }

  if(err.code ==='ECONNABORTED'){
    errorObj = {
        isError: true,
        message: "API Timeout",
        description: "The timeout period elapsed prior to completion of the operation or the server is not responding",
        type: 'error'
      };
  } else {
    errorObj = {
      isError: true,
      message:   err?.response?.data?.title ||err?.response?.data?.errorCode || err?.response?.statusText || err?.code,
      description: err?.response?.data?.detail || err?.message,
      additionalDescription: additionalDescriptionFn(err?.response?.data?.validationErrors),
      type: 'error'
    }
  }
  return errorObj;
}

const getAPI = (url: string, params?: any, replaceError?: {title: string, detail: string}) => {
  const options = getOptions();
  options.params = params
  return axios.get(url, options).then((res: AxiosResponse) => { return Promise.resolve(res) })
    .catch((err: AxiosError) => {
      if (replaceError && err.response?.data) {
        err.response.data = {
          ...err.response.data,
          ...replaceError
        }
      }
      const errorObj = getErrorObject(err);
      return Promise.reject(errorObj);
    })
}

const getAPIV2 = (url: string, responseType: 'blob', params?: any) => {
    const options: any = getOptionsV2();
  options.params = params;
  options.responseType = responseType;
  // options.withCredentials = true;
  return axios.get(url, options).then((res: AxiosResponse) => {return Promise.resolve(res) })
      .catch((err: AxiosError) => {
        const errorObj = getErrorObject(err);
        return Promise.reject(errorObj);
      })
}

const postAPI = (url: string, body: any) => {
  const options = getOptions();
  if (body instanceof FormData) {
    options.headers = {
      ...options.headers,
      "Content-Type": "multipart/form-data",
    };
    return axios.post(url, body, options).then((res: AxiosResponse) => { return Promise.resolve(res) })
      .catch((err: AxiosError) => {
        const errorObj = getErrorObject(err);
        return Promise.reject(errorObj);
      })
  } else {
    //  regular JSON POST requests
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json; charset=utf-8",
    };
    return axios.post(url, JSON.stringify(RemoveEmptyAttributes(body)), options)
      .then((res: AxiosResponse) => { return Promise.resolve(res) })
      .catch((err: AxiosError) => {
        const errorObj = getErrorObject(err);
        return Promise.reject(errorObj);
      })
  }
}

const deleteAPI = (url: string, body?: any) => {
  const options = getOptions();
  return axios.delete(url, options).then((res: AxiosResponse) => { return Promise.resolve(res) })
    .catch((err: AxiosError) => {
        const errorObj = getErrorObject(err);
      return Promise.reject(errorObj);
    })
}

const putAPI = (url: string, body?: any) => {
  const options = getOptions();
  return axios.put(url, JSON.stringify(RemoveEmptyAttributes(body)), options).then((res: AxiosResponse) => { return Promise.resolve(res) })
    .catch((err: AxiosError) => {
        const errorObj = getErrorObject(err);
      return Promise.reject(errorObj);
    });
}

export {
  getErrorObject,
  getAPI,
  postAPI,
  deleteAPI,
  putAPI,
  getAPIV2
}
