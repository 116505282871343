interface IConfig {
  authUrl: string;
  sentryDsn: string;
  meUrl: string;
  entityUrl: string;
  featuresUrl: string;
}
const defaultConfig: IConfig = {
  authUrl: "https://accountsint.iqmetrix.net",
  sentryDsn: "https://31b30bcb656c4802a7d85cdadd6629b5@o163465.ingest.sentry.io/5601913",
  meUrl: `https://accountsint.iqmetrix.net/v1/me`,
  entityUrl: `https://entitymanagerint.iqmetrix.net/v1/parententity`,
  featuresUrl: `https://featuresint.iqmetrix.net/v1`,
};

const Config: IConfig = {
  ...defaultConfig,
};

export { Config };

