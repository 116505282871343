import './App.css';
import { useReducer, useState, useEffect } from "react";
import { AuthProvider, authReducer, useCompany, useLogout, useSession } from "./auth";
import { SessionHandler } from './handlers/SessionHandler';
import { InteropHandlers } from './handlers/InteropHandlers';
import { NotificationContextProvider } from './components/utils/notificationContext';
import { CustomNotification } from './components/notification/customNotification';
import { ConfigProvider, Result } from 'antd';
import * as FullStory from '@fullstory/browser';
import { IQMHeader, IQMLayout } from '@iqm-ui-library/booster';
import { IQMAdvReconciliationTheme } from './theme.config';
import { LoaderContextProvider } from './components/utils/loaderContext';
import { CustomLoader } from './components/loader/customLoader';
import { ErrorContextProvider } from './components/utils/errorContext';
import { SuccessContextProvider } from './components/utils/successContext';
import { RouterProvider } from 'react-router-dom';
import { CustomAlert } from './components/alert/customAlert';
import reconciliationRouter from './router';

import './home.scss'

function App() {

    function initFullStory(orgId: string, companyName: string, userName: string) {
        FullStory.init({ orgId: orgId },
            ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`));
        FullStory.identify(userName + '(' + companyName + ')', {
            id: authState.me?.Id,
            email: authState.me?.Email
        });
    }

    const [authState, authDispatch] = useReducer(authReducer, {
        referrer: null,
        isLoggedIn: false,
    });

    useEffect(() => {
        if(process.env.REACT_APP_FULLSTORY_ENABLED === 'true' && authState.isLoggedIn && authState.parentEntity?.Name && authState.me?.FirstName){
            initFullStory('o-1A2CZG-na1', authState.parentEntity?.Name, authState.me?.FirstName + ' ' + authState.me?.LastName);
        }
    }, [authState.me, authState.parentEntity]);


    const [selectedTheme, setSelectedTheme] = useState(IQMAdvReconciliationTheme);
    const logout: any = useLogout();

    function logoutSession() {
        logout();
    }

    return (
        <div className="App">
            <ConfigProvider theme={selectedTheme}>
                {/* <CordovaApp host={"https://cc-tiger.eng.dxpcloud.net"}/>*/}
                <NotificationContextProvider>
                    <AuthProvider value={{ state: authState, dispatch: authDispatch }}>
                        <SessionHandler permissionsRequired='ReconcilePro'>
                            <InteropHandlers>
                                <LoaderContextProvider>
                                    <CustomLoader />
                                    <ErrorContextProvider>
                                        <SuccessContextProvider>
                                            <IQMLayout key={"IQM-HOME-LAYOUT"} className={'home'}>
                                                <IQMLayout style={{ marginTop: 64 }}>
                                                    { (authState.permissionInfo?.Permissions && authState.permissionInfo.Permissions.filter(obj => obj.Granted).length < 1) ? (
                                                        <>
                                                            <IQMHeader 
                                                                center={{ title: 'ReconcilePro' }}
                                                                right={{ user: {
                                                                    id: authState.me?.Id,
                                                                    firstName: authState.me?.FirstName,
                                                                    lastName: authState.me?.LastName,
                                                                    email: authState.me?.Email,
                                                      
                                                                  }, notifications: [], isDrawer: false, companyName: authState.parentEntity?.Name, onClick: (e: any) => logoutSession() }}
                                                            />                                                        
                                                            <Result
                                                                status="403"
                                                                title={ "Oops!" } 
                                                                subTitle={
                                                                    <>
                                                                    <div style={{ fontSize: '16px' }}>It looks like you don't have access to this application right now.</div>
                                                                    <div style={{ fontSize: '16px' }}>If you think you should, just reach out to your admin or support team, and they’ll get you sorted.</div>
                                                                    </>
                                                                }
                                                            />
                                                        </>
                                                     ) : (
                                                        <>
                                                            <RouterProvider router={reconciliationRouter} future= {{v7_startTransition: true}}/>
                                                            <CustomAlert /> 
                                                        </>
                                                    )}
                                                </IQMLayout>
                                            </IQMLayout>
                                        </SuccessContextProvider>
                                    </ErrorContextProvider>
                                </LoaderContextProvider>
                            </InteropHandlers>
                        </SessionHandler>
                    </AuthProvider>
                    <CustomNotification />
                </NotificationContextProvider>
            </ConfigProvider>
        </div>
    );
}

export default App;
