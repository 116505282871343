import { useContext } from 'react';
import { API_Endpoints } from '../constants/endpoints';
import { IFields, ITemplate } from '../constants/template.interface';
import { getAPI, postAPI } from './http.service';
import { AxiosResponse } from 'axios';
import { LoaderContext } from '../components/utils/loaderContext';
import { NotificationContext } from '../components/utils/notificationContext';

export class TemplateService {
    private endpoints = API_Endpoints();
    loaderContext = useContext(LoaderContext);
    private notificationContext = useContext(NotificationContext);

    getTemplateById = async (id: number) => {
        this.loaderContext.setLoader(true);
        return await getAPI(this.endpoints.getTemplateURL + id)
            .then((res: AxiosResponse) => {
                this.loaderContext.setLoader(false);
                return res.data;
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({...err})
            });
    }

    getTemplateList = async () => {
        return await getAPI(this.endpoints.getTemplateListURL)
            .then((res: AxiosResponse) => {
                return res.data;
            }).catch(err => {
                this.notificationContext.setNotification({...err})
            });
    }

    submitTemplate = async (data: ITemplate) => {
        data.dataStartRowNumber = data.dataStartRowNumber || null;
        data?.fields?.forEach((field: IFields) => (field.columnIdentifier = field.columnIdentifier));

        this.loaderContext.setLoader(true);
        if (!data.id) {
            return await postAPI(this.endpoints.createTemplateURL, data)
            .then((res: AxiosResponse) => {
                this.loaderContext.setLoader(false);
                return res;
            }).catch((err) => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({...err})
            })
        } else {
            return await postAPI(this.endpoints.updateTemplateURL, data)
            .then((res: AxiosResponse) => {
                return res;
            }).catch((err) => {
                this.notificationContext.setNotification({...err})
            }).finally(()=>{
                this.loaderContext.setLoader(false);
            })
        }
    }
}

export const TemplateService2 = {
    getTemplateById: async (id: number) => {
        return await getAPI(API_Endpoints().getTemplateURL + id)
    }
}