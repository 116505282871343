import {ParentEntity, MeResponse, useToken, PermissionInfo} from "../auth";
import axios from "axios";
import { meEndpoint, parentEntityEndpoint } from "../constants";
import { useCallback, useContext, useMemo } from "react";
import { getErrorObject } from "../services/http.service";
import { NotificationContext } from "../components/utils/notificationContext";
import { Config } from "../constants/config";

const useHttp = () => {
  const { getToken } = useToken();
  const notificationContext = useContext(NotificationContext);

  const getMe = useCallback(async (): Promise<MeResponse> => {
    const token = await getToken();
    const headers = { Authorization: `Bearer ${token.value}` };
    try {
      const response = await axios.get(meEndpoint(), { headers });
      return response.data as MeResponse;
    } catch (e:any) {
      const errorObj: any = getErrorObject(e);
      notificationContext.setNotification({...errorObj})
      return e;
    }
  }, [getToken]);

  const getParentEntity = useCallback(async (): Promise<ParentEntity> => {
    const token = await getToken();
    const headers = { Authorization: `Bearer ${token.value}` };
    try {
      const response = await axios.get(parentEntityEndpoint(), { headers });
      return response.data as ParentEntity;
    } catch (e:any) {
      const errorObj: any = getErrorObject(e);
      notificationContext.setNotification({...errorObj})
      return e;
    }
  }, [getToken]);

  const getPermissionInfo = useCallback(async (permissionsRequired: string): Promise<PermissionInfo> => {
    const token = await getToken();
    const headers = { Authorization: `Bearer ${token.value}` };
    if(!permissionsRequired){
      return {Permissions: null, Error: null};
    }
    try {
      const companyId =  localStorage.getItem('companyId');
      const response = await axios.get(`${Config.featuresUrl}/entity(${companyId})?features=${permissionsRequired}`, { headers });
      // Transform response.data to match PermissionInfo structure
      const permissions = response.data.map((feature: any) => ({
          Permission: feature.NameKey,
          Granted: feature.Toggle
      }));
      let permissionInfo: PermissionInfo = {Permissions: permissions, Error: null}
      return permissionInfo;
    } catch (e:any) {
      const errorObj: any = getErrorObject(e);
      notificationContext.setNotification({...errorObj})
      return e;
    }
  }, [getToken]);


  const httpClient = useMemo(
    () => ({
      getMe,
      getParentEntity,
      getPermissionInfo
    }),
    [getMe,getParentEntity,getPermissionInfo]
  );

  return { httpClient };
};

export { useHttp };
